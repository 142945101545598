<template>
  <div>
    <static-fullscreen-card :title="false"> Добро пожаловать в систему повышения вашей квалификации</static-fullscreen-card>
  </div>
</template>

<script>
import { getAccess, genModel, doubleClickDetect, keyDetect } from "@/components/mixings";
export default {
  mixins: [getAccess, genModel, doubleClickDetect, keyDetect],
  components: {},
  data() {
    return {
      deep: [{ id: 0, text: "/" }],
      idItemShow: 0,
      idEdit: 0,
      showEditDialog: false,
      dataTable: [],
      title: "",
      parent_id: 0,
      m: this.$store.getters["config/get"].models.education.chain,
      m2: {
        title: "",
        isDocument: false,
        api: "/mechti/education/chain",
        types1: [
          { value: 1, text: "Текст" },
          { value: 2, text: "Медиа" },
          { value: 3, text: "Вопрос" },
          { value: 4, text: "Тест" },
        ],
        types: [
          { value: 1, text: "Тема" },
          { value: 2, text: "Ответ" },
        ],
        list: [
          { name: "id", title: "#", type: "id", sortable: true, width: 150 },
          { name: "name", title: "Наименование", type: "string", sortable: true, width: 450 },
          { name: "type", title: "Тип", type: "select", sourceList: "types", sortable: !true },
          { name: "is_parent", title: "", type: "folder", style: "white-space: normal;", sortable: true, width: 50 },
        ],
        form: [
          { name: "is_parent", title: "Раздел", type: "switcher", validator: ["req"], default: 0 },
          { name: "name", title: "Наименование", type: "string", validator: ["req"] },
          { name: "type", title: "Тип", type: "select", sourceList: "types" },
          { name: "status", title: "Статус", validator: ["req"], type: "number", default: 1 },
          { name: "parent_id", default: 0 },
          { name: "accessRoles", title: "для роли..", type: "selectAccess2", accessType: "role", validator1: ["req"], default: [] },
          { name: "accessUsers", title: "для пользователя..", type: "selectAccess2", accessType: "user", validator1: ["req"], default: [] },
        ],
        config: {
          watch: "is_parent",
          default: { fields: "is_parent,name,status,parent_id", fieldsForm: "is_parent,name", fieldsRO: "" },
          0: {
            title: "Урок",
            fields: "is_parent,name,type,status,parent_id,accessRoles,accessUsers",
            fieldsForm: "is_parent,name,type,accessRoles,accessUsers",
            fieldsRO: "",
          },
          1: { title: "Раздел", fields: "is_parent,name,status,parent_id,accessRoles,accessUsers", fieldsForm: "is_parent,name,accessRoles,accessUsers", fieldsRO: "" },
        },
      },
    };
  },
  created() {
    this.$root.title = "Система обучения";
  },

  computed: {
    defaults() {
      let res = {
        sort: [
          { key: "is_parent", order: "DESC" },
          { key: "id", order: "ASC" },
        ],
        filters: { OR: [{ id: this.parent_id }, { parent_id: this.parent_id }] },
        folder_id: this.parent_id,
        paramName: "",
      };
      return res;
    },
    url() {
      return this.m.api;
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.$refs.table.updateData();
      }
    },
  },
  methods: {
    onGoto(e) {
      if (this.deep.length == 1) return;
      let idx = this.deep.findIndex((d) => d.id == e.id);
      if (idx < 0) return;
      let parent = this.deep[idx].id;
      let deep = [...this.deep];
      deep.splice(idx + 1);
      this.deep = deep;
      this.parent_id = parent;
    },
    itemShowClose() {
      this.loading = true;
      this.$refs.table.updateRowData(this.idItemShow);
      this.loading = !true;
      this.idItemShow = 0;
    },

    onClickRow(d) {
      if (d.field.name == "actions") {
      } else if (d.row.is_parent) {
        if (this.parent_id != d.row.id) {
          this.parent_id = d.row.id;
          this.deep.push({ id: d.row.id, text: d.row.name });
        } else {
          this.parent_id = d.row.parent_id;
          let deep = [...this.deep];
          deep.splice(this.deep.length - 1);
          this.deep = deep;
        }
        return;
      }
      this.idItemShow = d.row.id;
    },
    createNew() {
      this.showEditDialogFun(0);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
  },
};
</script>
